@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("../src/asset/css/font.css");

body {
  margin: 0;
  font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont,
    Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif,
    Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  /* -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    "Poppins", sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
* {
  margin: 0;
  font-family: "poppins", "SF UI Text", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.font-poppis {
  font-family: "poppins";
}
.font-dmserif {
  font-family: "DM Serif Text";
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* // fix Tailwind CSS border styles,form Tailwind CSS's preflight */
*,
::before,
::after {
  /* box-sizing: border-box; 1 */
  border-width: 0; /* 2 */
  border-style: solid; /* 2 */
  border-color: theme("borderColor.DEFAULT", currentColor); /* 2 */
}

::before,
::after {
  --tw-content: "";
}

body {
  background: #f5f5f5;
}

.service .ant-card-actions > li:first-child {
  width: 75% !important;
  padding-left: 24px;
}

.custom-progress-track {
  border-radius: 9.5px;
  overflow: hidden;
  background: #e3e3e3;
  box-shadow: 0px 4px 4px 0px rgba(202, 202, 202, 0.25) inset;
}

.custom-progress-track .progress-fill {
  border-radius: 0;
  box-shadow: 0px 4px 4px 0px rgba(202, 202, 202, 0.25) inset;
}

.custom-progress-track .progress-fill:first-child {
  border-bottom-left-radius: 9.5px;
  border-top-left-radius: 9.5px;
}

.custom-progress-track .progress-fill:last-child {
  border-bottom-right-radius: 9.5px;
  border-top-right-radius: 9.5px;
}

.tracking-card .ant-card-body {
  padding: 20px 12px 10px 12px;
}

.horizontal-Menu {
  /* width: 800px !important; */
  color: #c8c8c8 !important;
  background: #d11b1b00 !important;
  border: none;
}
.horizontal-Menu .ant-menu-item-selected {
  color: #fff !important;
  /* text-decoration: none !important; */
}
.horizontal-Menu .ant-menu-item:hover {
  color: #fff !important;
  /* text-decoration: none !important; */
}
.horizontal-Menu .ant-menu-item::after {
  position: relative !important;
  /* text-decoration: none !important; */
}
.horizontal-Menu .ant-menu-submenu-selected .ant-menu-submenu-title {
  color: #fff !important;
  /* text-decoration: none !important; */
}
.horizontal-Menu .ant-menu-item-selected::after {
  /* border: 0px !important; */
  text-decoration: none;
  border-bottom-color: #ffffff00 !important;
}
.horizontal-Menu .ant-menu-submenu-selected::after {
  /* border: 0px !important; */
  text-decoration: none;
  border-bottom-color: #ffffff00 !important;
}
.horizontal-Menu .ant-menu-item-active:hover {
  /* border: 0px !important; */
  text-decoration: none;
  color: #ffffff !important;
  border-bottom-color: #ffffff00 !important;
}
.horizontal-Menu .ant-menu-item-active:hover::after {
  /* border: 0px !important; */
  text-decoration: none;
  color: #ffffff !important;
  border-bottom-color: #ffffff00 !important;
}
.horizontal-Menu .ant-menu-submenu-active:hover {
  /* border: 0px !important; */
  text-decoration: none;
  color: #ffffff !important;
  border-bottom-color: #ffffff00 !important;
}
.horizontal-Menu .ant-menu-submenu:hover::after {
  /* border: 0px !important; */
  text-decoration: none;
  color: #ffffff !important;
  border-bottom-color: #ffffff00 !important;
}

.page-topbar {
  border-radius: 10px !important;
  box-shadow: 0px 3px 14px rgba(48, 59, 145, 0.5882352941);
  background: #2838bc;
  z-index: 100;
}

.login-card .ant-card-body {
  padding: 0px;
}

.login-card .ant-card-body .login-form {
  padding: 24px;
}
