/* fonts.css */
@font-face {
  font-family: "DM Serif Text";
  font-weight: 400;
  font-style: italic;
  src: url("../font/DMSerifText-Italic.ttf") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "DM Serif Text";
  font-weight: 400;
  font-style: normal;
  src: url("../font/DMSerifText-Regular.ttf") format("truetype");
  font-display: swap;
}
